var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "300px"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              {
                staticClass: "profileContainer",
                attrs: { "justify-space-between": "", row: "", wrap: "" }
              },
              [
                _c("v-flex", { attrs: { shrink: "" } }, [
                  _c("span", { staticClass: "profile__userName" }, [
                    _vm._v("Plattegronden")
                  ])
                ]),
                _vm.allowEdit &&
                !_vm.syncing &&
                !_vm.report.is_offline &&
                !_vm.report.isClosed
                  ? [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "", "text-xs-right": "" } },
                        [
                          !_vm.isEditor
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  staticClass: "cursor-pointer",
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.navigateToExpertEditor()
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [_vm._v("edit")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    868465459
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v("Plattegronden toevoegen")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.isEditor && !_vm.disabled
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  staticClass: "cursor-pointer",
                                                  nativeOn: {
                                                    click: function($event) {
                                                      _vm.isCreatingFloorplan = true
                                                      _vm.selectedFloorplan = null
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [_vm._v(" add ")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3105419907
                                  )
                                },
                                [_c("span", [_vm._v("Plattegrond toevoegen")])]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm.floorplans && _vm.floorplans.length
              ? [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "list-group",
                        on: {
                          start: function($event) {
                            _vm.drag = true
                          },
                          end: function($event) {
                            ;(_vm.drag = false), _vm.updateMediaOrder()
                          }
                        },
                        model: {
                          value: _vm.floorplans,
                          callback: function($$v) {
                            _vm.floorplans = $$v
                          },
                          expression: "floorplans"
                        }
                      },
                      "draggable",
                      _vm.dragOptions,
                      false
                    ),
                    [
                      _c(
                        "transition-group",
                        _vm._l(_vm.floorplans, function(mapPicture, index) {
                          return _c(
                            "div",
                            {
                              key: "floorplans-" + index,
                              staticClass: "mediaContainerWithDescription"
                            },
                            [
                              _c("div", { staticClass: "mediaContainer" }, [
                                _vm.allowEdit &&
                                !_vm.syncing &&
                                !_vm.report.is_offline &&
                                !_vm.report.isClosed &&
                                !_vm.disabled
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mediaContainer__actions"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "action" },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "sortIcon" },
                                              [_vm._v("drag_indicator")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: mapPicture.original,
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "action" },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("remove_red_eye")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "action",
                                            on: {
                                              click: function($event) {
                                                return _vm.editFloorplan(
                                                  mapPicture
                                                )
                                              }
                                            }
                                          },
                                          [_c("v-icon", [_vm._v("edit")])],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "action",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteFloorplan(
                                                  mapPicture
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "delete" },
                                              [_vm._v("delete")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c("img", { attrs: { src: mapPicture.thumb } })
                              ]),
                              _c(
                                "span",
                                { staticClass: "mediaContainer__name" },
                                [_vm._v(_vm._s(mapPicture.description))]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ]
              : _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("NoDataCard", {
                          attrs: {
                            icon: "satellite",
                            text: "Er zijn geen plattegronden"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
          ]
        : _vm._e(),
      _vm.isCreatingFloorplan
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500", persistent: true },
              model: {
                value: _vm.isCreatingFloorplan,
                callback: function($$v) {
                  _vm.isCreatingFloorplan = $$v
                },
                expression: "isCreatingFloorplan"
              }
            },
            [
              _c("AttachmentDialog", {
                attrs: {
                  report: _vm.report,
                  attachment: _vm.selectedFloorplan,
                  type: "maps"
                },
                on: { attachmentCreated: _vm.loadFloorplans },
                model: {
                  value: _vm.isCreatingFloorplan,
                  callback: function($$v) {
                    _vm.isCreatingFloorplan = $$v
                  },
                  expression: "isCreatingFloorplan"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }