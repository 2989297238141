import Storage from '@/support/Storage';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Report as ReportModel, Media } from '@/models/Report';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { File as FileModel } from '@/models/File';
import draggable from 'vuedraggable';
import { Options } from '@/components/mi-dialog/MiDialog';
import AttachmentDialog from '@/views/Reports/attachment-dialog/AttachmentDialog.vue';

@Component<Plattegronden>({
  components: {
    draggable,
    AttachmentDialog,
  },
})
export default class Plattegronden extends Vue {
  public $pageTitle = 'Plattegronden';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  @Prop({ default: true })
  protected allowEdit!: boolean;

  @Prop({ default: false })
  protected isEditor!: boolean;

  @Prop({ default: false })
  protected disabled!: boolean;

  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isCreatingFloorplan = false;

  protected floorplans: Media[] = [];

  protected selectedFloorplan: Media | null = null;

  protected dragOptions: any = {
    animation: 200,
    disabled: this.disabled,
    ghostClass: 'ghost',
  };

  protected async mounted() {
    this.isLoading = true;
    await this.loadFloorplans();

    this.isLoading = false;
  }

  protected async loadFloorplans() {
    const response = await this.report.listMedia({
      type: 'floorplans',
    })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (response.data) {
      this.floorplans = response.data;
    }
  }

  protected updateMediaOrder() {
    let fileIds: string[];

    fileIds = this.floorplans.map((file: Media) => file.uuid as string);

    if (! fileIds.length) {
      return;
    }

    new FileModel()
      .sortFiles(fileIds)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected editFloorplan(media: Media) {
    this.selectedFloorplan = media;
    this.isCreatingFloorplan = true;
  }

  protected deleteFloorplan(attachment: Media) {
    this.selectedFloorplan = attachment;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteImage);
  }

  protected get dialogOptionsDeleteImage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze bijlage wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          color: 'warning',
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            if (! this.report || ! this.selectedFloorplan) {
              return;
            }

            this.report
              .deleteMediaEndpoint(this.selectedFloorplan.uuid ? this.selectedFloorplan.uuid : '')
              .then((response: AxiosResponse) => {
                this.loadFloorplans();
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedFloorplan = null;
          },
        },
      },
    };
  }

  protected navigateToExpertEditor() {
    Storage.set('expert_activeTab', 'tab-bijlagen');
    this.$router.push(`/expert/${this.report.uuid}`);
  }
}
